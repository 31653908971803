import Stomp from "webstomp-client";
import {base64ToBytes, transportUid} from "./util";
import pako from 'pako';

export const Transport = {
    url: '/topic/web',
    handlers: [],
    uid: transportUid(),
    uidHandlers: [],
    reconnect: false,
    totalReceivedBytes: 0,
    totalReceivedCount: 0,
    useHttp: () => {
        return window.location.hostname === "localhost"
            || window.location.hostname === "127.0.0.1"
            || window.location.hostname === "::1"
            || window.location.hostname.startsWith("192.168.")
            || window.location.hostname.startsWith("10.0.");
    },
    connect: () => {
        let ws;
        if (Transport.useHttp()) {
            ws = new WebSocket('ws://' + window.location.hostname + ':15670/ws');
        } else {
            //ws = new WebSocket('wss://' + window.location.hostname + ':15673/ws');
            ws = new WebSocket('wss://' + window.location.host + '/ws');
        }

        ws.onclose = function (e) {
            console.log('Websocket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function () {
                Transport.connect();
            }, 1000);
        };

        ws.onerror = function (e) {
            console.log('Websocket is in error state. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function () {
                Transport.connect();
            }, 1000);
        };

        const client = Stomp.over(ws);
        client.reconnect_delay = 250;

        client.debug = function () {
            // No operations.
        };
        const onConnect = () => {
            const textDecoder = new TextDecoder();

            client.subscribe(Transport.url, (d) => {
                //console.log("Received: '" + d.body + "'.");
                try {
                    let body = d.body;

                    const beforeMegabytes = Math.floor(Transport.totalReceivedBytes / 1024 / 1024);
                    Transport.totalReceivedBytes += body.length;
                    Transport.totalReceivedCount++;
                    const afterMegabytes = Math.floor(Transport.totalReceivedBytes / 1024 / 1024);
                    if (beforeMegabytes !== afterMegabytes || Transport.totalReceivedCount % 10 === 0) {
                        console.log("Received in total " + Transport.totalReceivedBytes / 1024 / 1024
                            + " MB in " + Transport.totalReceivedCount + " messages")
                    }

                    if (body.length > 0 && body.charAt(0) !== '{') {
                        //const length = body.length;
                        body = textDecoder.decode(pako.inflate(base64ToBytes(body)));
                        //console.log("Uncompressed " + length + " -> " + body.length + " bytes");
                    }
                    const json = JSON.parse(body);
                    //console.log(JSON.stringify(json));
                    Transport.handlers.forEach(handler => handler(json));
                } catch (e) {
                    console.log('This doesn\'t look like a valid JSON: ', d.body, e);
                }
            });

            client.subscribe("/topic/" + Transport.uid, (d) => {
                // console.log("uid::Received: '" + d.body + "'.");
                try {
                    let body = d.body;

                    const beforeMegabytes = Math.floor(Transport.totalReceivedBytes / 1024 / 1024);
                    Transport.totalReceivedBytes += body.length;
                    Transport.totalReceivedCount++;
                    const afterMegabytes = Math.floor(Transport.totalReceivedBytes / 1024 / 1024);
                    if (beforeMegabytes !== afterMegabytes || Transport.totalReceivedCount % 10 === 0) {
                        console.log("Received in total " + Transport.totalReceivedBytes / 1024 / 1024
                            + " MB in " + Transport.totalReceivedCount + " messages")
                    }

                    if (body.length > 0 && body.charAt(0) !== '{') {
                        //const length = body.length;
                        body = textDecoder.decode(pako.inflate(base64ToBytes(body)));
                        //console.log("uid::Uncompressed " + length + " -> " + body.length + " bytes");
                    }
                    const json = JSON.parse(body);
                    //console.log("uid(" + Transport.uid + "):" + JSON.stringify(json));
                    //console.log("uid::got " + body.length + " bytes.");
                    Transport.uidHandlers.forEach(handler => handler(json));
                } catch (e) {
                    console.log('uid::This doesn\'t look like a valid JSON: ', d.body, e);
                }
            });

            Transport.client = client;
            Transport.send(JSON.stringify({action: 'nop', requestTimeMillis: +new Date()}));
        };
        const onError = function (e) {
            console.log(JSON.stringify(e));
        };

        client.connect('guest', 'guest', onConnect, onError, '/');
        console.log("Client connected")
        Transport.client = client;

        if (!Transport.reconnect) {
            Transport.reconnect = true;
            setInterval(() => {
                const readyState = Transport.client.ws.readyState;
                if (readyState >= 2) {
                    console.log("Client reconnecting...")
                    setTimeout(() => {
                        Transport.connect();
                    }, 100);
                }
            }, 1000);
        }
    },

    addHandler: (handler) => Transport.handlers.push(handler),

    addUidHandler: (handler) => Transport.uidHandlers.push(handler),

    send: (message, destination) => {
        const readyState = Transport.client.ws.readyState;
        if (readyState === 0) {
            console.log("Transport.client.ws.readyState=" + readyState + ": wait 1 s and and send.");
            setTimeout(() => Transport.send(message), 1000);
        } else if (readyState >= 2) {
            console.log("Transport.client.ws.readyState=" + readyState);
            Transport.connect();
            setTimeout(() => Transport.send(message), 1000);
        } else if (Transport.client) {
            Transport.client.send(destination ? destination : Transport.url, message)
        } else {
            // alert("Ой, кажется антивирус заблокировал соединение по вебсокетам. Возможно, у вас проблемы с соединением по сети."
            //     + " Попробуйте отключить антивирус, сменить интернет-соединение или браузер.")
        }
    }
};

Transport.connect();
